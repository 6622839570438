import React, { useState, useEffect } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ product }) => {
  if (!product || product.isHide) return null

  const [selectValue, setSelectValue] = useState()
  const [selectOpen, setSelectOpen] = useState(false)

  return (
    <div className={styles.product}>
      <div className={styles.image}>
        <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(product.image))} alt={product.image?.caption} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: product.title }} />
          <div className={styles.plans}>
            <div className={styles.plansInfo}>
              <div className={styles.top}>
                <p className={styles.protocol}>{product.protocol}</p>
                {product.rating?.url && (
                  <Link to={product.rating.url} className={styles.rating}>
                    {product.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(product.rating.starsImage)} alt={product.rating?.text}/>}
                    {product.rating.text && <span className={styles.ratingText}>{product.rating.text}</span>}
                  </Link>
                )}
              </div>
              {selectValue && selectValue.save && (
                <p className={styles.save}>
                  <p className={styles.saveValue}>{selectValue.save}</p>
                  <p className={styles.saveText}>{selectValue.text}</p>
                </p>
              )}
            </div>
            <div className={styles.plansControls}>
              <div className={cx(styles.select, {
                [styles.selectOpen]: selectOpen,
                [styles.selectEmpty]: !selectValue
              })} onClick={() => setSelectOpen(!selectOpen)}>
                {!selectValue && <p className={styles.selectValue}>{product.selectLabel}</p>}
                {selectValue && (
                  <p className={styles.selectValue}>
                    <span>{selectValue.period}</span>
                    <span dangerouslySetInnerHTML={{ __html: selectValue.price }} />
                  </p>
                )}
                <ul className={styles.selectList}>
                  {product.subscriptions && product.subscriptions.map(plan => (
                    <li key={plan._key} className={styles.selectOption} onClick={() => setSelectValue(plan)}>
                      <span>{plan.period}</span>
                      <span dangerouslySetInnerHTML={{ __html: plan.price }} />
                    </li>
                  ))}
                </ul>
              </div>
              {!selectValue && <a href={product.defaultButtonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{product.defaultButtonTitle}</a>}
              {selectValue && <a href={selectValue.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{selectValue.buttonTitle}</a>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
