import React from 'react'
import styles from './style.module.sass'

export default ({ quote }) => {
  if (!quote || quote.isHide) return null
    
  return (
    <div className={styles.quote}>
      <div className={styles.wrap}>
        <p className={styles.title}>{quote.title}</p>
        <h2 className={styles.text}>{quote.text}</h2>
      </div>
    </div>
  )
}