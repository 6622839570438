import React, { useEffect, useState } from 'react'
import { PopupButton } from '@typeform/embed-react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { clickTrack, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/format'
import { Link } from 'gatsby'
import Lottie from 'react-lottie';
import achievementLottieData from '../../../images/lottie/achievement'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ questions }) => {
  if (!questions || questions.isHide) return null

  const [userAnswers, setUserAnswers] = useState([])
  const [achievementLottieIsStopped, setAchievementLottieIsStopped] = useState(true)

  const achievementLottieOptions = {
    loop: false,
    autoplay: false,
    animationData: achievementLottieData,
    rendererSettings: {
      className: styles.animation
    }    
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const goToNextQuestion = key => {
    setUserAnswers([...userAnswers.filter(k => k !== key), key])

    if (key === questions.items[questions.items.length - 1]._key) {
      gsap.to(window, {
        duration: 1,
        scrollTo: document.getElementById('questions-result'),
        onComplete: () => setAchievementLottieIsStopped(false)
      })
    } else {
      const questionIndex = questions.items.findIndex(q => q._key === key)
      gsap.to(window, {
        duration: 1,
        scrollTo: document.getElementById(questions.items[questionIndex + 1]._key)
      })
    }
  }

  return (
    <div className={styles.questions}>
      <div className={styles.wrap}>
        <div className={styles.items}>
          {questions.items &&
            questions.items.map(s => (
              <div key={s._key} className={styles.item}>
                <div id={s._key} className={styles.anchor} />

                <div className={styles.steps}>
                  {questions.items.map((q, index) => (
                    <div className={cx(styles.step, {
                      [styles.stepActive]: q._key === s._key
                    })}>{index + 1}</div>
                  ))}
                </div>

                <p className={styles.title}>{s.title}</p>

                <div className={cx(styles.buttons, {
                  [styles.buttonsInColumn]: s.buttons && s.buttons.length > 2
                })}>
                  {s.buttons && s.buttons.map((q, index) => (
                    <div key={index} className={styles.button} onClick={() => goToNextQuestion(s._key)}>{q}</div>
                  ))}
                </div>

                <p className={styles.info}>{prepareParagraph(s.information)}</p>
                <p className={styles.source}>{prepareParagraph(s.resources)}</p>
              </div>
            ))}
        </div>

        <div className={styles.bottom}>
          <div id="questions-result" className={styles.anchor} />
          {userAnswers.length === questions.items.length ? (
            <>
              <Lottie isPaused={achievementLottieIsStopped} className={styles.animation} options={achievementLottieOptions} />
              <h2 className={styles.bottomTitle}>{questions.titleParticipants}</h2>
            </>
          ) : (
            <h2 className={styles.bottomTitle}>{questions.titleNonParticipants}</h2>
          )}

          <div className={styles.buttons}>
            <PopupButton
              id={questions.button1Url.replace('https://quiz/', '')}
              as="a"
              onReady={() => clickTrack(questions.button1Title)}
              transitiveSearchParams={true}
              className={cx(styles.button, styles.buttonBlue)}
            >{questions.button1Title}</PopupButton>
            <Link to={questions.button2Url} className={styles.button}>{questions.button2Title}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
