import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ stats }) => {
  if (!stats || stats.isHide) return null

  return (
    <div className={styles.stats}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{stats.title}</h2>
        <p className={styles.description}>{stats.description}</p>
        <div className={styles.items}>
          {stats.items && stats.items.map(item => (
            <div className={styles.item} key={item._key}>
              <p className={styles.value}>{item.title}</p>
              {item.rating?.url ? (
                <Link to={item.rating.url} className={styles.rating}>
                  {item.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(item.rating.starsImage)} alt={item.rating?.text}/>}
                  {item.rating.text && <span className={styles.ratingText}>{item.rating.text}</span>}
                </Link>
              ) : (
                <p className={styles.info} dangerouslySetInnerHTML={{ __html: item.description }} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}