import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ header }) => {
  if (!header) return null

  return (
    <div className={styles.header}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h1 className={styles.title}>{header.title}</h1>
          <p className={styles.description}>{header.description}</p>
          <a href={header.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{header.buttonText}</a>
        </div>
        <div className={styles.images}>
          {header.images && header.images.map(item => (
            <div className={styles.image} key={item._key}>
              <img className={styles.imageSrc} src={sanityImageUrl(item.image)} alt={item.image?.caption} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}