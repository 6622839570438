import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ steps }) => {
  if (!steps || steps.isHide) return null
    
  return (
    <div className={styles.steps}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img
            {...srcSetProps(sanityImageUrl(steps.image))}
            alt={steps.image?.caption}
            className={styles.imageSrc}
          />
        </div>
        <div className={styles.text}>
          <p className={styles.title}>{steps.title}</p>
          <div className={styles.list}>
            {steps.items.map((step, index) => (
              <div key={index} className={styles.step}>
                <div className={styles.index}>{index + 1}</div>
                <div className={styles.column}>
                  <p className={styles.info}>{step.title}</p>
                  <p className={styles.description}>{step.desciption}</p>
                </div>
              </div>
            ))}
          </div>
          <a href={steps.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>{steps.buttonTitle}</a>
        </div>
      </div>
    </div>
  )
}

