import React from 'react'
import { sanityImageUrl, urlWithSearchParamsHandler } from '../../../utils/format'
import { Link } from 'gatsby'
import SwiperObj, { Pagination, FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Protocol from './protocol'
import styles from './style.module.sass'

export default ({ protocols }) => {
  if (!protocols || protocols.isHide) return null

  SwiperObj.use([Pagination, FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.1,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    pagination: {
      el: `.${styles.dots}`,
      clickable: true
    },
    className: styles.slider
  }

  return (
    <div className={styles.protocols}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{protocols.title}</h2>
        {protocols.rating?.url && (
          <Link to={protocols.rating.url} className={styles.rating}>
            {protocols.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(protocols.rating.starsImage)} alt={protocols.rating?.text}/>}
            {protocols.rating.text && <span className={styles.ratingText}>{protocols.rating.text}</span>}
          </Link>
        )}
        <div className={styles.items}>
          {protocols.list && protocols.list.map(protocol => (
            <Protocol key={protocol._key} protocol={protocol}/>
          ))}
        </div>
        <Swiper {...sliderSettings}>
          {protocols.list && protocols.list.map(protocol => (
            <SwiperSlide key={protocol._key} className={styles.sliderItem}>
              <Protocol protocol={protocol}/>
            </SwiperSlide>
          ))}
          <div className={styles.dots} />
        </Swiper>          
      </div>
    </div>
  )
}
